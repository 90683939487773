/** @format */

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./App.css";
import { Nav, OverlayTrigger, Tab, Tooltip } from "react-bootstrap";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import Press from "./Press";
import ReactGA from "react-ga";
ReactGA.initialize("UA-261507667-1");
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  return (
    <div className='App'>
      <Press />
      <Header />
      <h1>Oscar Multichain Staking</h1>
      <div className='tab-box'>
        <Tab.Container
          id='left-tabs-example'
          // className='m-auto'
          defaultActiveKey='Binance'>
          <Nav variant='pills' className='flex-row  m-auto nav-img mt-5'>
            <Nav.Item className='m-auto'>
              <Nav.Link href='https://stakebsc.oscarswap.com' target='_blank'>
                <OverlayTrigger
                  placement='bottom'
                  overlay={<Tooltip>Binance Smart Chain (Live Soon)</Tooltip>}>
                  <img src='bnb.png' width={80} className='mx-2' alt='' />
                </OverlayTrigger>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='m-auto'>
              <Nav.Link href='https://stakearb.oscarswap.com' target='_blank'>
                <OverlayTrigger
                  placement='bottom'
                  overlay={<Tooltip>ARBITRUM (Live)</Tooltip>}>
                  <img
                    src='arb.png'
                    className='mx-2 img-fade'
                    width={80}
                    alt=''
                  />
                </OverlayTrigger>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='m-auto'>
              <Nav.Link href='https://stakeeth.oscarswap.com' target='_blank'>
                <OverlayTrigger
                  placement='bottom'
                  overlay={<Tooltip>Ethereum (Coming Soon)</Tooltip>}>
                  <img
                    src='eth.png'
                    className='mx-2 img-fade'
                    width={80}
                    alt=''
                  />
                </OverlayTrigger>
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            {/* <Tab.Pane eventKey='ARBITRUM'>
          <iframe
            title='stake'
            src='https://stakebsc.oscarswap.com'
            width='100%'
            height='590px'></iframe>
        </Tab.Pane>
        <Tab.Pane eventKey='Binance'>
          <Home />
        </Tab.Pane> */}
          </Tab.Content>
        </Tab.Container>
      </div>
      <Footer />
      {/* {COMMIT_VERSION ? (
      <Version variant='caption'>Version: {COMMIT_VERSION}</Version>
    ) : null} */}
    </div>
  );
}

export default App;
